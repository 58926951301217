import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { parseData } from "../utils/storage";

import router from "../router";

Vue.use(Vuex);

const prefixAPI = "eclient/"

/* const url = "http://127.0.0.1:3333/";
const baseURL = "http://127.0.0.1:3333/" + prefixAPI; */

const url = "https://mrfoody.com.mx/";
const baseURL = "https://mrfoody.com.mx/" + prefixAPI;


const axiosToken = axios.create({
  baseURL: baseURL,
  headers: {
    common: {
      Authorization: "Bearer " + parseData(localStorage.getItem("auth"), "token") || ""
    }
  }
});

export default new Vuex.Store({
  state: {
    status: "",
    user: parseData(localStorage.getItem("auth"), "person") || "",
    token: parseData(localStorage.getItem("auth"), "token") || "",
    establishment:
      parseData(localStorage.getItem("auth"), "establishments") != null
        ? parseData(localStorage.getItem("auth"), "establishments")[0]
        : "",
    selectedEstablishment: 
      parseData(localStorage.getItem("auth"), "establishments") != null
        ? parseData(localStorage.getItem("auth"), "establishments")[localStorage.getItem("selectedEstablishmentIndex") || 0]
        : "",
    selectedEstablishmentIndex: localStorage.getItem("selectedEstablishmentIndex") || 0,
    establishments:
      parseData(localStorage.getItem("auth"), "establishments") != null
        ?  parseData(localStorage.getItem("auth"), "establishments")
        : "",
    establishmentMenu: Object,
    zoom: 100,
    darkMode: parseData(localStorage.getItem("settings"), "darkMode") || false,
    userXpress: null,
    establishmentStatus: Object,
    establishmentLocation:
      parseData(localStorage.getItem("auth"), "establishments") != null
        ? {
          lat: parseData(localStorage.getItem("auth"), "establishments")[0]
            .latitude,
          lng: parseData(localStorage.getItem("auth"), "establishments")[0]
            .longitude,
        }
        : "",
    orderType: "",
    activeOrders: null,
    backendServer: url,
    currentTime: new Date(),
    packageVersion: process.env.VERSION || "0",
    appSound: localStorage.getItem("appSound") || "1",
    support: "",
    selectedMenu: localStorage.getItem("selectedMenu") || 0,
    xpressOrder: localStorage.getItem("xpressOrder") || null,
    xpressAddress: localStorage.getItem("xpressAddress") || null,
    newOrder: localStorage.getItem("newOrder") || false,
  },
  mutations: {
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, { user, establishment, token }) {
      state.status = "success";
      state.user = user;
      state.establishment = establishment;
      state.token = token;
    },
    auth_error(state) {
      state.status = "error";
    },
    google_api_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.user = "";
    },
    change_zoom(state, payload) {
      state.zoom = payload;
    },
    set_appSound(state, payload) {
      state.appSound = payload;
      localStorage.setItem("appSound", payload)
    },
    set_darkMode(state, payload) {
      state.darkMode = payload;
      const data = parseData({ darkMode: payload });
      localStorage.setItem("settings", data);
    },
    set_menu(state, payload) {
      state.establishmentMenu = payload;
    },
    set_location(state, payload) {
      state.establishmentLocation = payload;
    },
    auth_userXpress(state, payload) {
      state.userXpress = payload;
    },
    establishment_status(state, payload) {
      state.establishmentStatus = payload;
    },
    set_establishment(state, payload) {
      state.selectedEstablishmentIndex = payload.index;
      state.selectedEstablishment = payload.establishment;

      localStorage.setItem("selectedEstablishmentIndex", payload.index);
    },
    set_order_type(state, payload) {
      state.orderType = payload;
    },
    set_orders_active(state, payload) {
      state.activeOrders = payload;
    },
    set_current_time(state, payload) {
      state.currentTime = payload;
    },
    support(state, payload) {
      state.support = payload;
    },
    set_selected_menu(state, payload) {
      state.selectedMenu = payload;
    },
    set_current_saved_xpress_order(state, payload) {
      state.xpressOrder = payload;
    },
    set_current_save_xpress_address(state, payload) {
      state.xpressAddress = payload;
    },
    set_new_order(state, payload) {
      state.newOrder = payload;
    },
  },
  actions: {
    async delete({ commit }, location) {
      commit("auth_request");
      let resp;
      try {
        commit("auth_request");
        resp = await axiosToken.delete(location);

        if (resp.request.responseURL.includes("login")) {
          router.push("/login");
        } else {
          return resp.data;
        }
      } catch (err) {
        commit("auth_error");
      }
    },
    async get({ commit }, payload) {
      commit("auth_request");
      let resp;
      try {
        const params = payload.params || '';
        commit("auth_request");
        resp = await axiosToken.get(payload.location, { params });

        if (resp.request.responseURL.includes("login")) {
          router.push("/login");
        } else {
          return resp.data;
        }
      } catch (err) {
        commit("auth_error");
      }
    },
    async post({ commit }, payload) {
      let resp;
      try {
        commit("auth_request");
        resp = await axiosToken({
          url: payload.route,
          data: payload.data,
          method: payload.method ? payload.method : "POST",
        });

        if (resp.request.responseURL.includes("login")) {
          router.push("/login");
        } else {
          return resp.data;
        }
      } catch (err) {
        commit("auth_error");
      }
    },
    async put({ commit }, payload) {
      let resp;
      try {
        commit("auth_request");
        resp = await axiosToken({
          url: payload.route,
          data: payload.data,
          method: payload.method ? payload.method : "PUT",
        });

        if (resp.request.responseURL.includes("login")) {
          router.push("/login");
        } else {
          return resp.data;
        }
      } catch (err) {
        router.push("/login");
        commit("auth_error");
      }
    },
    async login({ commit }, userLogIn) {
      commit("auth_request");
      let resp;
      try {
        resp = await axiosToken.post("login-electron", userLogIn);

        const user = resp.data.person;
        const establishment = resp.data.establishments[0];
        const token = resp.data.token;
        const data = parseData(resp.data);

        if (!resp.data.error) {
          localStorage.setItem("auth", data);
          axiosToken.defaults.headers.common["Authorization"] = "Bearer " + token;

          const latitude = establishment.latitude;
          const longitude = establishment.longitude;
          commit("set_location", { lat: latitude, lng: longitude });

          commit("auth_success", { user, establishment, token });
        } else {
          commit("auth_error");
        }

        return resp.data;
      } catch (err) {
        commit("auth_error");
        localStorage.removeItem("auth");
      }
    },
    async getGoogleApi({ commit }, payload) {
      let resp;
      try {
        const _axios = axios.create();
        delete _axios.defaults.headers.common['Authorization'];
        resp = await _axios.get(payload.location);
    
        return resp;
      } catch (err) {
        commit("google_api_error");
      }
    },
    refresh() {
      return new Promise((resolve, reject) => {
        axios({
          url: baseURL + "refresh",
        })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    async logout({ commit }) {
      let resp;
      try {
        commit("logout");
        resp = await axiosToken.get("logout");
        localStorage.removeItem("auth");
        delete axios.defaults.headers.common["Authorization"];
        return resp.data;
      } catch (err) {
        commit("auth_error");
      }
    },
  },
  getters: {
    isLoggedIn: (state) => !!state.token,
    authStatus: (state) => state.status,
    establishmentId: (state) => state.selectedEstablishment.id,
    getEstablishmentName: (state) => state.selectedEstablishment.name,
    establishmentMenu: (state) => state.establishmentMenu,
    establishmentLocation: (state) => state.establishmentLocation,
    isUserXpressLogged: (state) => !!state.userXpress,
    userXpress: (state) => state.userXpress,
    getAppSound: (state) => state.appSound,
    getEstablishmentStatus: (state) => state.establishmentStatus,
    getDarkModeStatus: (state) => state.darkMode,
    getOrderType: (state) => state.orderType,
    getActiveOrders: (state) => state.activeOrders,
    isXpress: (state) => state.selectedEstablishment.xpress,
    appVersion: (state) => state.packageVersion,
    getCurrentTime: (state) => state.currentTime,
    getEstablishment: (state) => state.selectedEstablishment,
    getEstablishments: (state) => state.establishments,
    getSchedules: (state) => state.selectedEstablishment.schedules,
    getServer: (state) => state.backendServer,
    getEstablishmentDeliveryPaymentType: (state) => state.selectedEstablishment.delivery_payment_type,
    getSupport: (state) => state.support,
    getSelectedEstablishmentIndex: (state) => state.selectedEstablishmentIndex,
    getSelectedMenu: (state) => state.selectedMenu,
    getXpressOrder: (state) => state.xpressOrder,
    getXpressAddress: (state) => state.xpressAddress,
    isSavedXpressOrder: (state) => !!state.xpressOrder,
    isSavedXpressAddress: (state) => !!state.xpressAddress,
    isNewOrder: (state) => state.newOrder,
  },
});
